// ***********************************
// BREADCRUMBS
// ***********************************
.breadcrumbs{line-height: 1; @extend .clearfix;}
.breadcrumbs ul{margin:0; padding:0; list-style:none;}
.breadcrumbs ul li{ float: none; display:inline-block; color: $green-c; font-weight: 500; position: relative; font-size: 0.8125rem;}
.breadcrumbs ul li:after{
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  right: 4px; top: 50%;
  @include center(y);
  color: #777; font-weight: normal;
}
.breadcrumbs ul li:last-of-type:after{display: none;}
.breadcrumbs ul li:first-child{padding-left:0;}
.breadcrumbs ul li a{ text-decoration:none; position: relative; font-weight: normal; }
.breadcrumbs ul li a{ padding-right: 15px; cursor: pointer; color: #777;}
.breadcrumbs ul li:last-of-type a{padding-right: 0}
.breadcrumbs ul li a:hover{ color: $green-c; }
.breadcrumbs ul li.current,
.breadcrumbs ul li.current a{cursor: default; text-decoration: none; pointer-events: none; color: $red-c; text-transform: uppercase; font-weight: 600; }