.gc-display-area {
  border: none;
  -webkit-box-shadow: 3px 2px 4px 2px rgba(0,0,0,0.25); 
  box-shadow: 3px 2px 4px 2px rgba(0,0,0,0.25);
  padding: 0;
}

.gc-zoom {
  padding: 0;
}

.gc-zoom-inner {
  left: 0!important;
  top: 0!important;
}

.glass-case ul li {
  border: 0;
  background-color: $white-c;
}
.glass-case ul li.gc-active, .glass-case ul li.gc-active:hover {
  background-color: $color1-c;
  cursor: default;
}

.gc-icon {
  border: none;
  width: 38px;
  height: 42px;
  text-align: center;
  line-height: 1;
  
}
.gc-display-area .gc-icon-prev, .gc-thumbs-area-prev .gc-icon-prev,
.gc-display-area .gc-icon-next, .gc-thumbs-area-next .gc-icon-next
{
  font-size: 3em !important;
  z-index: 2;
}
.gc-thumbs-area-prev.gc-disabled, gc-thumbs-area-next.gc-disabled{opacity: 0;}

.gc-icon-close {
  font-size: 2.7em;
  height: 38px;
}

@include media-breakpoint-down(sm) {
  .gc-display-area {
    pointer-events: none;
  }
}