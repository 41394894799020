/* Services Baner - Home & Page Services List */

.services{
  margin-bottom: 40px;

 

  .box:nth-child(3n+1){ clear: both; }

  .text-block{

    padding: 0 10px;

    .title-s{
      color: $brown;
      font-size: 15px;
      margin-top: 2em;
      margin-bottom: 2em;
      text-transform: uppercase;

      &:after{bottom:30px; transform: scaleX(1); transition: transform .7s;}

      

      a{transition: .7s ease-in-out;}

     
    }

    .title-s-line{
      content: "";
      height: 3px;
      width: 50px;
      background: $green-c;
      font-size: 1px;
      left: 50%;
      display: flex;
    }
  }

  p{
    font-size: 12px;
    color: #777; text-align: center;
    margin-top: 2em;
  }

  .service{
    margin-top: 2em;

    > a{

      img{
        padding: 10px;
        border-radius: 50%;
        border: 1px solid #316eaf;
        width: 50%;
        display: flex;
        margin: auto;
      }
      
    }

   

    .round{
      padding: 10px;
      border-radius: 50%;
      border: 1px solid $green-c;
      width: 90%;
    }

    &:hover a{
      color: $green-c;
      //@include transition-all(.7s);
    }

    &:hover .title-s:after{transform: scaleX(1.5);}

  }

  .service:hover h3{
    color: $green-c;
    @include transition-all(.4s);
  }
}

.services-page{
  .services{
    margin: 0;
    @extend .clearfix;
  }

  .text-editor{
    margin-bottom: 30px;
    padding: 0 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .services {
    .service{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}