
.newsletter-section {
  padding: 0 0 45px 0;
  position: relative;

  #form_newsletter {
    padding: 20px;
    width: 760px;
    margin: 0 auto;
    background: $gray-f;
    color: $black-c;
    position: relative;

    .text-input{
      width: 50%;
    }

    .gcaptcha-submit{
      position: absolute;
      right: 0;
      width: 50%;
      top: 0;
      padding: 20px;
      box-sizing: border-box;
      height: 100%;

      .g-recaptcha{
        width: 50%;
        transform:scale(0.55);
        -webkit-transform:scale(0.55);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        display: inline-block;
        float: left;
      }
      .submit-box{
        width: 50%; float: right;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }

    .submit_box{/*width: 92px;*/  text-align: center }

    input[type="text"]{
      margin-top: 2px;
      color: $black-c;
      background: none transparent;
      padding: 10px 15px;
      border-radius: 10px;
    }

    input[type="submit"]{
      background: none;
      border: 1px solid $green-c;
      text-transform: uppercase;
      font-weight: 700;
      color: $green-c;
      cursor: pointer; padding: 10px 15px;
      border-radius: 10px;
    }
  }

  #newsleter_form_error{
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    font-weight: bold;
    left: 0;
  }
}

@include media-breakpoint-down(md) {
  .newsletter-section {
    padding: 0 20px 45px;
    display: inline-block;

    #form_newsletter {width:730px;}
  }
}
@include media-breakpoint-down(sm) {
  .newsletter-section {
    width: 100%;

    #form_newsletter {
      width:100%;

      .text-input{width:100%;}
      .gcaptcha-submit{
        width:100%;
        position: static;
        padding: 20px 0;
        margin-bottom: 24px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .newsletter-section #form_newsletter input[type="submit"] {
    z-index: 2;
    background: $color2-c;
    position: relative;
  }
}

@media (max-width: 600px){
  .newsletter-section{

    #form_newsletter{

      .gcaptcha-submit{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .submit-box{
          width: 100%;
        }

      }

    }

  }
}