// ***********************************
// VARIABLES
// ***********************************

// TYPOGRAPHY
$font-family1: 'Montserrat', sans-serif;

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }



// COLORS
$white-c: #fff;
$black-c: #555;
$gray-d: #333333;
$gray-c: #ddd;
$red-c: #E82C2C;
$gray-f:  #F9F9F9;
$green-c: #316EAF;
$green-c1: #fff;
$blue: #004D8D;
$brown: #545454;
$brown-2: #BDA09C;


$text-c: $black-c;
$rectangle-c: $black-c;
$border-c: $gray-c;

$color1-c: $green-c;
$color2-c: $green-c1;

$alert-color: $red-c;
$success-color-c: $green-c;

$block-margin-bottom: 40px;