/* News - Home Latest News, List News & Article */

/* Home List News*/
.latest-news{
  margin-bottom: /*$block-margin-bottom*/ 80px;
}

.news{
  border:1px solid #DDDDDD;

  .text_block{
    padding: 20px;

    .date_author_comments{
      display: inline-block; width: 100%;
      border-bottom: 1px solid #dddddd;
      color: #777;
      padding-bottom: 7px;
      margin-bottom: 10px;

      >div{display: inline-block;}

      span{ padding-right: 7px; color: $green-c; }

      .date{ padding-right: 20px; }
      .author{ }
      .comments{ }
    }

    h2, h3{
      font-weight: bold; font-size: $font-size-h3;
      margin-bottom: 12px;
    }

    a{
      color: $green-c;
    }

    .short_text {
      height: 68px;
      display: table-cell;
      vertical-align: middle;
      width: 100%;
    }
  }

  &:hover{
    img{opacity: 0.8; @include transition-all(.4s);}

    h2 a, h3 a{color: $green-c; @include transition-all(.4s);}
  }

}

/* News - Article */
.article{
  .date_comments_location_author{
    margin-bottom: 20px;
  }
  .short-text{
    font-family: 'Open Sans', sans-serif;
    font-size: $font-size-h3;
    color: $gray-d;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .text-editor{padding-top: 20px;}

  .tag-list{
    border-bottom: 1px dotted #999;
    padding:15px 0;
    margin-bottom: 20px;

    span{font-weight: bold;}

    span, .tags, li{display: inline-block;}

    li a{color: $color1-c;}

    li a:hover{color: $color1-c;}

  }

  #comments{
    display: none;
  }

  .gallery-on-page{
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .news{
    margin-bottom: 2em;
  }

  .sidebar{
    display: none;
  }
}
