html, body {
  height: 100%;
}

html {
  overflow: visible!important;
}

.col-xs-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  float: left;
  margin-bottom: 0;
}

@media (max-width: 991px){  .col-xs-15 {margin-bottom: 20px;}}

@media (min-width: 576px) {
  .col-xs-15 {
    width: 50%;
    float: left;
  }
}

@media (min-width: 768px) {
  .col-xs-15 {
    width: 33%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-xs-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-xs-15 {
    width: 20%;
    float: left;
  }
}

@include media-breakpoint-only(xs) {
  html {
    font-size: 14px;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

body {
  font-family: $font-family1;
  font-size: 0.9375rem;
  color: $text-c;
  overflow-x: hidden;
}

.page-padding, .padding-block {
  padding-bottom: 50px;
}

.section-title-t1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.75rem;
  font-weight: 700;
  span {
    display: inline-block;
    position: relative;
    padding: 0 5px;
    @include transition-all(.4s);
    &:before {
      content: '';
      position: absolute;
      top: 50%; right: 100%;
      margin-right: 5px;
      width: 2000px; height: 1px;
      background-color: $border-c;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%; left: 100%;
      margin-left: 5px;
      width: 2000px; height: 1px;
      background-color: $border-c;
    }

    &:hover {
      color: $red-c;
    }
  }
}

.section-title-t2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 60px;
  span {
    display: inline-block;
    position: relative;
    padding-bottom: 25px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0; left: 0;
      width: 90px; height: 1px;
      background-color: $color2-c;
    }
  }
}

.section-subtitle-t1 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 4px;
  color: #aaa;
}


@include media-breakpoint-up(sm) {

}

@include media-breakpoint-between(sm, lg) {

}

@include media-breakpoint-down(lg) {

}



.image-wrapper {
  // Adding a loader and background color. The user will see it
  // if the image is loading slow.
  background: #f4f4f4 url('../images/loading.gif') center center no-repeat;
  width: 100%
}
.ratio_3-4 {
  // The image has a 16/9 ratio. Until the image has loaded
  // we need to reserve some space so the page won't reflow.
  // How to calculate the space (padding-bottom): 9/16*100 = 56.25
  // Another example: you have an image 400x250.
  // So if you want to calculate the space you do: 250/400*100 = 62.5
  padding-bottom: 133.33333333%;
  height: 0;
}

.ratio_2-1 {
  // The image has a 16/9 ratio. Until the image has loaded
  // we need to reserve some space so the page won't reflow.
  // How to calculate the space (padding-bottom): 9/16*100 = 56.25
  // Another example: you have an image 400x250.
  // So if you want to calculate the space you do: 250/400*100 = 62.5
  padding-bottom: 50%;
  height: 0;
}

.b-lazy {
  max-width: 100%;
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0;
}
.b-lazy.b-loaded {
  opacity: 1;
}

.webpage-overlay {
  opacity: 0; visibility: hidden;
  top: -100%;
}

a.btn-tr {
  border: 1px solid $green-c;
  color: $green-c;
  position: relative;
  display: inline-block;
  text-align: center;
  box-shadow: none;
  border-radius: 10px;
  line-height: 20px;
  padding: 10px 18px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;

  @include transition-all(.4s);

  i {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 18px;
    @include center(y);
    @include transition-all(0.4s);
  }
  &:hover {
    padding-right: 38px;
    border: 1px solid $green-c;
    color: $green-c;
    i {
      opacity: 1;
      visibility: visible;
    }
  }
}

a.btn-c{
  border-color: $green-c;
  color: $color1-c;

  &:hover {
    border: 1px solid $green-c;
    color: $green-c;
  }

  i {
    color: $green-c;
  }
}

.title-b{
  text-align: center;
  font-size: $font-size-h1; font-weight: 400;
  position: relative;
  padding: 50px 0 50px;
  color: $gray-d;

  &:after{
    content: "";
    height: 1px; width: 100px;
    background: #DDDDDD;
    font-size: 1px;
    position: absolute; left:50%; bottom:29px;
    margin-left: -50px;
  }
}

.title-s{
  color: $black-c;
  font-size: $font-size-h3; font-weight: bold;
  text-align: center;
  position: relative;
  

  &:after{
    content: "";
    height: 3px; width: 50px;
    background: $color2-c;
    font-size: 1px;
    position: absolute; left:50%; bottom:25px;
    margin-left: -25px;
  }
}

.title-p{
  font-size: $font-size-h1; font-weight: 500; color: $color1-c; text-align: center; padding:15px 0 30px;
}

.title-p2{
  font-size: $font-size-h1; font-weight: 500; color: $green-c !important; position: relative; padding: 0 0 50px;

  &:after{
    content: "";
    height: 1px; width: 100px;
    background: #DDDDDD;
    font-size: 1px;
    position: absolute; left:0; bottom:29px;
  }
}

.shop-results{
  text-align: center;
  padding: 0 !important;

  hr{
    width: 10%;
    margin-top: 24px;
  }

  &:after{
    content: "";
    height: 1px; width: 100px;
    background: none;
  }
}

.productsingle-title{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24px !important;
  color: $gray-d !important; 
  border-bottom: none !important;
  padding: 0 0 15px;

  &:after{
    content: "";
    height: 1px; width: 100px;
    background: none;
  }
}

.price

.page-title h2{
  text-align: center;
  margin-top: -15px;
  padding-bottom: 30px;
}

.breadhrumbs-title-page{
  background: #fff;
  padding: 15px 0;
  margin-bottom: 15px;

  @extend .clearfix;

  .page-title{
    @extend .clearfix;
  }
}

.shop-forms{
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #F9F9F9;
  
  .form-search-infos{
    text-align: center;

    .form-positions{
      display: flex;
      justify-content: center;
      flex-direction: row;
  
     .search-locations, .search-locations2{
      width: 40%;
      margin-left: 3em;

      input, select{
        border-radius: 5px;
        height: 60px;
      }
    }
  
      
    }

    .button-search{
      margin-top: 40px;

      .serach-button-submit{
        background-color: $green-c;
        color: #fff;
        font-weight: 700;
        width: 250px;
        height: 60px;
        border: none;
        border-radius: 10px;
        cursor: pointer;

        &:hover{
          background-color: #fff;
          color: $green-c;
          border: 1px solid $green-c;
        }
      }
    }
  }
  
}

.serach-results{
  margin-top: 60px;
  font-family: 'Roboto', sans-serif;

  .search-title{
    font-weight: 500;
    font-size: 36px;
    color: $green-c;
  }

  .search-count{
    font-weight: 400;
    font-size: 15px;
  }

  .search-result-show{
    margin-top: 30px;
    width: 100%;
    margin-bottom: 100px;

    tr{
      border-top: 1px solid rgba(51, 51, 58, 0.3);
      border-bottom: 1px solid rgba(51, 51, 58, 0.3);
      

      td{
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    .location{
      font-weight: 700;
      font-size: 16px;
    }

    .address{
      .blue-address{
        font-weight: 700;
        color: $green-c !important;
      }
    }

    .phone{
      .phone-number{
        font-weight: 700;
        color: $green-c !important;
      }
    }

    .working-hour{
      text-align: center;
    }
  }
}



/* Text Editor all page */

.view-page, .article{
  .text-editor{margin-bottom: 1.25rem;}
}

/* Sidebar - Right column */
.sidebar{

  .s-block{

    .title-s2{
      font-size: $font-size-h4; font-weight: bold; padding: 0 0 10px; margin-bottom: 15px; border-bottom: 3px solid $green-c;
    }
  }

  div#sidebar.sidebar-second {
    display: none !important;
  }
}

/* Price List Page */
.price-list{
  h3{ margin-bottom: 10px;}

  table{
    margin-bottom: 10px;
    width: 70%;

    tr{
      background: #EEEEEE;

      &:nth-child(2n){
        background: #DDDDDD;
      }

      td{
        padding:4px 10px;

        &:nth-child(1){
          text-align: left; width: 80%;
        }

        &:nth-child(2){
          text-align: right;
          padding-right: 20px;
          width: 20%;
        }
      }
    }
  }
}

.cookieinfo{
  background: $green-c !important;

  .cookieinfo-close{
    background-color: #fff !important;
    color: $green-c !important;
  }
}

@include media-breakpoint-down(lg) {

  .price-list{
    table{width: 100% !important;}
  }

}

@media only screen and (max-width: 600px) {
  .shop-forms{
    .form-search-infos{
      .form-positions{
        flex-direction: column;
    
        .search-locations, .search-locations2{
          width: 100%;
          margin-top: 1em;
          margin-left: 0;
        }
      }
    }
  }

  .productsingle-title{
    margin-top: 2em;
  }
}