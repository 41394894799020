/* Products pages - catalogue pages */

.products-page{
  padding-top: 55px;

  /* All products page */
  .category{
    margin-bottom: 20px;

    img{
      width: 100%;
      margin-bottom: 15px;
    }

    .title-pr{
      font-weight: 700;
      font-size: 1.225rem;
      display: block;
      text-align: center;
    }
  }

  /* Products list - category */
  .product-box{
    text-align: center;
    margin-bottom: 30px;

    .product-item{
      border-bottom: 1px solid rgba(85, 85, 85, 0.3);
      position: relative;
      padding-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        -moz-transform: translate(-50%,0);
        -o-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        -webkit-transform: translate(-50%,0);
        transform: translate(-50%,0);
        width: 0;
        height: 1px;
        background-color: $color2-c;
        -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -ms-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
      }

      &:hover::before {
        width: 100%;
      }
    }

    &:nth-child(3n+1) {
      clear: both;
    }

    &:hover{
      .photo{
        border:1px solid $green-c;
      }

      .name{
        color: $green-c;
      }
    }

    .photo{
      
      display: block;
      position: relative;
      margin-bottom: 15px;
      @include transition-all(.4s);

      .brand{
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: block;
        width: 50px;
      }
    }

    .name-id{
      line-height: 1.4;

      .name{
        font-family: 'Roboto', sans-serif;
        display: block;
        padding-bottom: 7px;
        font-size: 18px;
        font-weight: 700;
        @include transition-all(.4s);
      }
      .id{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        display: block;
        padding-bottom: 7px;
        font-size: 14px;

        span{font-weight: 700;}
      }
    }

    .price{
      display: none;
      span{
        color: $color2-c;
        span{
          font-size: 1.225rem;
          font-weight: 700;
        }
      }
    }
  }
}

/* Product page */
.product-page{

  .back-cat{
    padding: 15px 0 30px;

    .back-link{
      float: right;
    }
  }

  .block-title{
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  a{
    color: $color2-c;
  }

  .box{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    text-align: justify;
    text-align: 23px;
    padding-bottom: 15px; padding-left: 7px;
    margin-bottom: 15px;
    // border-bottom: 1px solid #ddd;
  }

  .id{
    padding-left: 7px;
    span{font-weight: 600;}
  }

  .brend{
    span{font-weight: 600;}
  }

  .price{
    //padding-top: 15px;
    display: none !important;

    span{
      font-size: 1.225rem;
      font-weight: 700;
      color: $color2-c;
    }
  }

}