.social-position{
  margin: 40px 0;

  .social-links-holder {
    padding: 8px 0;
    border-top: 1px solid rgba(85, 85, 85, 0.3);
    border-bottom: 1px solid rgba(85, 85, 85, 0.3); font-size: 0;
  
    .block-title {
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
      display: inline-block;
      vertical-align: top;
      line-height: 40px;
      padding-right: 35px; font-size:  .9375rem;
    }
  
    .social-links {
      display: inline-block;
      @extend .clearfix;
  
      li {
        display: inline-block;
        float: left;
  
        &:not(:first-of-type) {
          a {
            border-left: 0;
          }
        }
      }
  
      a {
        display: block;
        line-height: 38px;
        width: 40px;
        border: 1px solid $border-c;
        color: #888;
        text-align: center;
        font-size: 1rem;
  
        &:hover {
          border-color: $green-c;
          background-color: $color2-c;
          color: $green-c;
        }
      }
    }
  }

}

