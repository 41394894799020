.c-slider {
  position: relative;
}

.c-slider-arr {
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  cursor: pointer;
  z-index: 1;
  @include translate(0, -50%);
}

//////////////////////////////
//HOMEPAGE SLIDER
//////////////////////////////
.homepage-slider {
  .c-slider-arr {
    color: white-c !important;
    border-color: $white-c !important;
    line-height: 30px; width: 30px;
    font-size: 30px;
    span{
      color: $white-c;
    }
    &.prev-slide {
      left: 40px;
      border-right: 4px solid;
    }
    &.next-slide {
      right: 40px;
      border-left: 4px solid;
    }
    &:hover, span:hover{
      color: $color2-c!important;
      border-color: $color2-c!important;
    }
    &:hover span {
      color: $color2-c !important;
      border-color: $color2-c !important;
      @include transition-all(.4s);
    }
  }

  .slick-dots{
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;

    li{
      display: inline-block;
      margin: 0 4px;

      button{
        border: 1px solid $black-c !important;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        padding: 0;
        margin: 0;
        text-indent: -999999px;
        background: none transparent;

        &:hover{
          background-color:  $black-c !important;
        }
      }

      &.slick-active{
        button{background-color:  $black-c;}
      }
    }

  }

  .slick-slide {
    position: relative;
    overflow: hidden;
  }

  .text-block-holder {   
    position: absolute;
    height: 100%; width: 100%;
    left: 0; top: 0;

    > .container {
      position: relative;
      height: 100%;
    }
  }

  .text-block {
    opacity: 1 !important;
    padding: 15px 20px !important;
    background: rgba(225, 229, 230, 0.95);
    text-align: left;
    padding: 0;
    display: inline-block;
    position: absolute;
    @include center(y);
    top:40%;
    left: 15px;
    opacity: 0;
    min-width: 32%;
    max-width: 38%;
    @include transition(opacity 2s 0.5s);
  }

  .text1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: $green-c;
    line-height: 1.2;
    text-transform: uppercase;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text2 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: $black-c;
    padding-top: 10px;
    padding-bottom: 20px;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    color: $black-c;
    padding-bottom: 25px;
    @include transition(opacity 2s 1s, left 2s);
  }

  .slick-active {

    .text-block {
      opacity: 1;
    }

    .text1, .text2, .text3 {
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    .link-block a {
      opacity: 1;
      top: 0;
      z-index: 1;
    }
  }
}

.homepage-slider {
  height: 450px;

  .slick-slide {
    height: 450px;
  }
}

@include media-breakpoint-down(md) {
  .homepage-slider {
    .text-block {top:50%; width: 80%;}
    .c-slider-arr.prev-slide { left: 5px; }
    .c-slider-arr.next-slide { right: 5px; }
  }
}

@include media-breakpoint-between(md, lg) {
  .homepage-slider {

    .text-block {
      padding: 20px 10px;
    }

    .text1 {
      font-size: 12px;
    }

    .text2 {
      font-size: 36px;
    }
  }

  .homepage-slider {
    height: 400px;

    .slick-slide {
      height: 400px;
    }
  }
}

@include media-breakpoint-only(sm) {
  .homepage-slider {

    .text-block {
      padding: 15px 10px;
    }

    .text1 {
      font-size: 12px;
    }

    .text2 {
      font-size: 24px;
    }
  }

  .homepage-slider {
    height: 300px;

    .slick-slide {
      height: 300px;
    }
  }
}

@include media-breakpoint-only(xs) {
  .homepage-slider {

    .c-slider-arr {
      display: none!important;
    }

    .text-block {
      padding: 15px 10px;
      left: 50%;
      @include translate(-50%, -50%)
    }

    .text1 {
      font-size: 11px;
      margin-top: 5px;
    }

    .text2 {
      font-size: 18px;
    }

    .link-block a {
      line-height: 15px;
      padding: 3px 10px;
      font-size: 10px;
    }
  }

  .homepage-slider {
    height: 250px;

    .slick-slide {
      height: 250px;
    }
  }
}

.fixed-right-icons{
  position: fixed;
  right: 0;
  top: 15em;
  z-index: 100;

  a{
    color: #fff;

    .fixed-right-contact{
      background-color: $green-c;
      color: #fff;
      width: 60px;
      height: 60px;
      text-align: center;
      padding-top: 6px;
      margin-bottom: 5px;
  
  
        p{
          text-transform: uppercase;
          font-weight: 600;
          font-size: 8px;
        }
    }
  }

  a{
    color: #fff;

    .fixed-right-contact-facebook{
      background-color: $green-c;
      color: #fff;
      width: 60px;
      height: 60px;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 5px;
  
  
        p{
          text-transform: uppercase;
          font-weight: 600;
          font-size: 8px;
        }
    }
  }

  a{
    .fixed-right-contact-instagram{
      background-color: $green-c;
      color: #fff;
      width: 60px;
      height: 60px;
      text-align: center;
      padding-top: 10px;
  
      p{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 600px){
  .homepage-slider{
    
    .text-block{
      max-width: 90%;
    }

    .text3{
      display: none;
    }

  }

  .fixed-right-icons{
  
    a{
      color: #fff;
  
      .fixed-right-contact{
        width: 40px;
        height: 40px;
        padding-top: 9px;
    
          p{
            display: none;
          }
      }
    }
  
    a{
      color: #fff;
  
      .fixed-right-contact-facebook{
        width: 40px;
        height: 40px;
    
    
          p{
            display: none;
          }
      }
    }
  
    a{
      .fixed-right-contact-instagram{
        width: 40px;
        height: 40px;
    
        p{
         display: none;
        }
      }
    }
  }
}

//////////////////////////////
// LATEST NEWS HOME SLIDER
//////////////////////////////
