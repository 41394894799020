#header {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #DDDDDD;

  section.top {
    background-color: $color1-c;
    color: $white-c;
    position: relative;

    .contact {
      display: inline-block;
      > div {
        display: inline-block;
        border-right:1px solid $color2-c;
        padding-right: 20px;
        a{
          color: $white-c;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;

          &:hover{
            opacity: 0.7;
          }
        }
        &:first-of-type {
          border-left:1px solid $color2-c;
        }
        &:hover{
          opacity: 0.7;
        }
      }
      .icon {
        display: inline-block;
        width: 40px; height: 38px;
        vertical-align: middle;
        text-align: center;
        line-height: 34px;
      }
      .phone .icon{font-size: 25px !important; }
      .email .icon{font-size: 25px !important; }
      .darker {
        color: #CBCBCB;
      }

      @include media-breakpoint-only(sm) {
        > div {
          display: block;
          line-height: 14px;

          .icon {
            // display: none;
          }
        }

        .email{display: none;}
      }

      @include media-breakpoint-only(xs) {
        .email{display: none;}
      }
    }

    .lang_social{
      float: right;

      #topbar-menu{
        float: left;
       
        padding: 6px 0px;
        line-height: 26px;

        ul{
          overflow: hidden;

          li{
            display: inline-block;
            padding-right: 12px;

            a{
              color: #fff;
            }

            ul.sub-menu.level1{
              display: none;
            } 
          }

          li.last{
            display: none;
          }
        }
      }

      > .languages {
        float: right;
        border-right:1px solid $color2-c;
        padding: 0 20px;
        line-height: 26px;

        .c-drop-trigger{line-height: 38px;}

        a{
          text-transform: capitalize;

          span:first-of-type {
            padding-right: 7px;
          }
        }

        .c-drop-content {
          width: auto!important;
        }

        .content-holder{
          padding: 0 20px;
          top: 45px;
          text-align: left;
          border: 3px solid $color1-c;
          background-color: $white-c;
        }

        li{

          a{
            font-weight: 600;
            font-size: 0.875rem;
            text-transform: none;
            color: $black-c;
            position: relative;
            display: block;
            text-align: left;
          }

          &:hover a{
            color: $color2-c;
          }
        }
      }
      .social{
        float: right;
        font-size: 0;

        a{
          color: $white-c;
          border-right:1px solid $color2-c;
          padding:0 20px;
          line-height:38px;
          font-size: 14px;
          display: inline-block;

          &:first-of-type {
            border-left:1px solid $color2-c;
          }

          &:hover{
            background: $color2-c;
          }
        }

      }
      @include media-breakpoint-down(md) {
        .social{display: none;}
      }
    }

  }

  section.main {
    font-size: 0;
    position: relative;

    .logo {
      float: left;
      margin: 30px 0 0;
    }

    .riht_main{
      float: right;
      padding: 10px 0;

      #search{
        float: right;
        padding: 11px 0;

        a.searchBtn{
          position: relative;

          span{
            font-size: 16px;
            width:35px; height: 35px;
            line-height: 35px;
            text-align: center;
            background: $color1-c;
            color: $white-c;
            display: none;

            &:hover{
              background: $color2-c;
            }
          }

        }
        .search {

          .content-holder{
            border:3px solid $color1-c;
            padding: 10px;
            background-color: #f5f5f5;
          }

          form {
            font-size: .975rem;
            border-bottom: 1px solid #ccc;
            position: relative;

            &:after{
              font-family: FontAwesome;
              content: '\f00e';
              position: absolute;
              right:5px; bottom: 0;
              color: $color2-c;
              font-size: 22px;

            }

            input.inputbox{
              background: none;
              border: none;
              font-style: italic;
              color: #999;
              padding: 5px 30px 5px 2px;
              margin: 0;
              display: inline-block;

              &:focus{
                font-style: normal;
                color: $color1-c;
              }
            }

            input.button_search{
              width: 30px; height: 30px;
              background-color: transparent;
              border: none;
              text-indent: -9999px;
              padding: 0;
              display: inline-block;
              position: absolute; right: 0; bottom: 0;
              cursor: pointer;
              z-index: 1;
            }
          }
        }
      }


    }





    @include media-breakpoint-down(md) {

      .logo {
        height: 40px;

        p {
          height: 100%;
        }

        img {
          max-height: 100%;
          width: auto;
        }
      }

      .riht_main{
        padding: 0;

        .search {

        }

        .navigation{
          padding: 25px 15px;
        }
      }

    }

    @include media-breakpoint-only(xs) {
      .logo {
        flex-basis: 100%;
        margin-bottom: 15px;
      }
    }

  }

}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

//default style
.c-drop-holder {
  position: relative;
  .c-drop-content {
    position: absolute;
    right: 0; top: 110%;
    width: 360px;
    opacity: 0; visibility: hidden;
    z-index: -1;
    @include transition(opacity 0.4s, top 0.4s);
  }
  .c-drop-trigger {
    @extend .noselect;
    cursor: pointer;
  }

  &.drop-open {
    .c-drop-content {
      top: 100%;
      opacity: 1; visibility: visible;
      z-index: 1;
    }
  }

  @include media-breakpoint-down(xs) {
    position: static;
    .c-drop-content {
      width: 100%!important;
    }
  }
}

.c-drop-holder {
  .c-drop-content {

  }
  .c-drop-trigger {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 600px) {
  #header{
    section.top{
      .lang_social{
        >.languages{
          border-right: none !important;
          padding: 0 !important;
        }
      }
      
    }
  }
}

@media only screen and (max-width: 360px) {
  #header{
    section.top{
      font-size: 10px;

      .contact{
        >div{
          border-right: 0 !important;
          padding-right: 0;

          &:first-of-type{
            border-left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  #header{
    section.top{
      font-size: 10px;

      .contact{
        >div{
          padding-right: 10px;
        }
      }
    }
  }
}